import message from 'antd/lib/message';
import { Rule } from 'antd/lib/form';
import { useLayoutEffect, useState } from 'react';

import { CURRENCY_SYMBOLS, PAGES_WITH_MAP } from './constants';
import { FF_CONSENT, MEDIA_BASE_URL } from './config';
import { TId, TPhotoItem, TPhotoSet } from 'types';

export type TItemProps = {
  label?: string;
  name: string;
  placeholder?: string;
  rows?: number;
  rules?: Rule[];
  validateTrigger?: string; // actually 'onBlur' | 'onChange';
};

export function deepClone<T>(data: T): T {
  if (!data) return data;
  return JSON.parse(JSON.stringify(data));
}

export function getDate(dateString: string): string {
  return dateString ? dateString.substring(0, 10).replace(/-/g, '/') : '';
}

export function pad(num: number): string {
  return `0${num}`.slice(-2);
}

/**
 * Converts minutes to HH:mm, preserves sign.
 * @param mins
 * @returns {string}
 */
export function minutesToHHmm(mins) {
  const sign = Math.sign(mins) > 0 ? '+' : '-';
  const _mins = Math.abs(mins);
  const h = Math.floor(_mins / 60);
  const m = _mins % 60;
  return `${sign}${pad(h)}:${pad(m)}`;
}

export function isFalsy(value) {
  return (
    value === false || value === void 0 || value === null || Number.isNaN(value)
  );
}

export function isNumber(x: any): boolean {
  return !Number.isNaN(x) && typeof x === 'number';
}

export function isDictionary(x) {
  if (!x) return false;
  return typeof x === 'object' && !x.filter;
}

export function areEqual(x: any, y: any): boolean {
  return (x && JSON.stringify(x)) === (y && JSON.stringify(y));
}

export function isProdHost(): boolean {
  return (
    window.location.hostname.startsWith('app') ||
    window.location.hostname.startsWith('www')
  );
}

export function isTestRunner(): boolean {
  return window.process ? process.env.NODE_ENV === 'test' : false;
}

export function log(x: any): void {
  window.console.log(JSON.stringify(x, null, 2));
}

/**/
export function isTouchDevice() {
  return 'ontouchstart' in document.documentElement || 'ontouchstart' in window;
}

/**
 * Shuffles array in place.
 */
export function shuffleArray(a: any[]): any[] {
  if (!Array.isArray(a)) return [];

  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

/**
 * String comparator util. Useful as a callback for `<string[]>.sort()`.
 */
export function stringComparator(a: any, b: any): number {
  if (!a || typeof a !== 'string') return -1;
  if (!b || typeof b !== 'string') return 1;
  return a.localeCompare(b);
}

/**
  useWindowSize - it's a hook which adds and removes 'resize' listener and
  return updated window sizes [width, height] each time the window is rotated or resized.
  Used to trigger a component update on 'resize/rotate' events.
 */
export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};

// Check Cordova platform
export function isCordova(): boolean {
  return window['isCordovaDriven'] === true;
}

/**
 *
 */
export function getSubdomain(): string {
  return window.location.host.split('.')[0];
}

// Check existing of 'FF_CONSENT' cookies
export const hasCookieConsent = () =>
  document.cookie.split('; ').find((item) => item.startsWith(FF_CONSENT));

export const dateComparator = (a: string, b: string): number => {
  const result = Date.parse(a) - Date.parse(b);

  return isNaN(result) ? stringComparator(a, b) : result;
};

export function getCurrencySymbol(currency: string): string {
  return currency ? CURRENCY_SYMBOLS[currency] : null;
}

// for transforming strings: LOREM IPSUM DOLOR -> Lorem Ipsum Dolor
export function toTitleCase(str: string): string {
  if (!str) return str;
  return str
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function getBase64(img: File, callback: (value: string) => void): void {
  const reader = new FileReader();

  reader.addEventListener('load', () => callback(reader.result as string));
  reader.addEventListener('error', message.error as any);
  reader.readAsDataURL(img);
}

export function updateArrayItem<T extends TId>(
  editedElement: T,
  array: T[]
): T[] {
  return array.map((item: T) =>
    item.id === editedElement.id ? editedElement : item
  );
}

export function mapPhotos(photos: TPhotoSet[]): TPhotoItem[] {
  if (!photos) return null;

  return photos.map(
    ({ big, original, thumbnail }: TPhotoSet): TPhotoItem => ({
      description: big.copyright || original.copyright || thumbnail.copyright,
      fullscreen: `${MEDIA_BASE_URL}/${big.path}`,
      original: `${MEDIA_BASE_URL}/${big.path}`,
      thumbnail: `${MEDIA_BASE_URL}/${thumbnail.path}`
    })
  );
}

export function isLocalHost(): boolean {
  return Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
}

/**
 *
 */
export function isMapLikePage(pathname): boolean {
  return !!PAGES_WITH_MAP.some(
    (path) => pathname === path || pathname.startsWith(path + '/')
  );
}

/**
 *
 */
export function randomStr(): string {
  return Math.random().toString(36).substring(2);
}
