export enum EUserAuthority {
  ACTIVITY_PROFILE = 'ACTIVITY_PROFILE',
  ADMIN = 'ADMIN',
  ADMIN_USER_MANAGEMENT = 'ADMIN_USER_MANAGEMENT',
  AQUAFACTS = 'AQUAFACTS',
  CREATE_NEWS = 'CREATE_NEWS',
  CRM = 'CRM',
  DEVELOPER = 'DEVELOPER',
  FISHFACTS = 'FISHFACTS',
  HISTORICAL_CATCH = 'HISTORICAL_CATCH',
  HISTORICAL_TRACK = 'HISTORICAL_TRACK',
  POOR_CONNECTION = 'POOR_CONNECTION',
  ROLE_ANONYMOUS = 'ROLE_ANONYMOUS',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  TRIAL_POPUP = 'TRIAL_POPUP',
  VESSEL_EXTENDED_FIELDS = 'VESSEL_EXTENDED_FIELDS',
  VIEW_ANALYTICS = 'VIEW_ANALYTICS',
  VIEW_ANNUAL_REPORT = 'VIEW_ANNUAL_REPORT',
  VIEW_BIOLYTICS = 'VIEW_BIOLYTICS',
  VIEW_HARVESTING = 'VIEW_HARVESTING',
  VIEW_OWNER = 'VIEW_OWNER',
  VIEW_MAP = 'VIEW_MAP',
  VIEW_MAP_ICE_LAYER = 'VIEW_MAP_ICE_LAYER',
  VIEW_USER_CUSTOM_AKER_BIOMARINE = 'VIEW_USER_CUSTOM_AKER_BIOMARINE',
  VIEW_USER_CUSTOM_COMPANY_X = 'VIEW_USER_CUSTOM_COMPANY_X',
  VIEW_USER_CUSTOM_NORDIC_WILDFISH = 'VIEW_USER_CUSTOM_NORDIC_WILDFISH',
  VIEW_USER_CUSTOM_SCANBIO = 'VIEW_USER_CUSTOM_SCANBIO',
  VIEW_USER_CUSTOM_VROLIJK = 'VIEW_USER_CUSTOM_VROLIJK'
}

export type TUserInfo = {
  authorities: ReadonlyArray<EUserAuthority>;
  eventsId: ReadonlyArray<number>;
  firstName: string;
  fleets: ReadonlyArray<{ id: number; name: string }>;
  groupId: number;
  groupName: string;
  id: number;
  lastName: string;
  newsId: ReadonlyArray<number>;
  serviceProvidersId: ReadonlyArray<number>;
  username: string;
};

export type TUser = Readonly<{
  name: string;
  token: string;
  userInfo: TUserInfo;
}>;
