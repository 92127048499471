import {
  L,
  TFarmLocation,
  TFarmLocationExt,
  TMinMax,
  TProviderAddressShort,
  TVesselLocation
} from 'types';
import { TVesselLocationExt, TVesselTrackPoint } from './vessel';

export enum EMapLayer {
  CONTOUR = 'CONTOUR',
  CURR = 'currents',
  EEZ = 'EEZ',
  GUSTS = 'gust',
  ICE = 'ICE',
  ICES = 'ICES',
  ICE_COVERAGE = 'ICE_COVERAGE',
  PHYTOPLANKTON = 'PHYTOPLANKTON',
  SALINITY = 'SALINITY',
  SEABED = 'SEABED',
  SEATEMP = 'SEATEMP',
  SURFACETEMP = 'SURFACETEMP',
  TEMP = 'temp',
  WAVES = 'waves',
  WIND = 'wind',
  ZOOPLANKTON = 'ZOOPLANKTON'
}

export enum EMapIceLayer {
  ANTARCTIC_1 = 'ANTARCTIC_1',
  ANTARCTIC_2 = 'ANTARCTIC_2',
  ANTARCTIC_3 = 'ANTARCTIC_3',
  BARENTS_SEA = 'BARENTS_SEA',
  EAST_GREENLAND = 'EAST_GREENLAND',
  GREENLAND = 'GREENLAND',
  SVALBARD = 'SVALBARD',
  WEST_GREENLAND = 'WEST_GREENLAND'
}

export enum EMapView {
  ATLANTIC = 'ATLANTIC',
  PACIFIC = 'PACIFIC'
}

export type TTrackPoint = {
  vesselId: number;
  point: TVesselTrackPoint;
  latlng: L.LatLng;
};

export enum EMarkerCategory {
  AREA = 'areas',
  CAGE = 'cages',
  LAYER_VALUE = 'layerValues',
  LOCATION = 'locations',
  SERVICE = 'services',
  VESSEL = 'vessels'
}

export type TLayerPointLocation = {
  coords: {
    x: number;
    y: number;
    z: number;
  };
  ij: {
    i: number;
    j: number;
  };
  latitude: number;
  longitude: number;
};

export type TLayerPointValue = {
  latitude: number;
  longitude: number;
  timeStamp: string;
  title: string;
  units: string;
  value: number;
};

export type TLocationCoordinates = {
  accuracy: number;
  heading: number;
  latitude: number;
  longitude: number;
  speed: number;
};

export type TPosition = [number, number];

export type TMapEntity =
  | TFarmLocation
  | TLayerPointValue
  | TProviderAddressShort
  | TVesselLocation;

export type TDraggableMapEntity = TFarmLocationExt | TVesselLocationExt;

export type TMapEntityState = {
  color?: string; // Track color. Vessels only.
  index?: number; // Position on the panel.
  panel: EMiniCardPanel; // If null, then the card isn't minimized.
  type: EMarkerCategory;
};

export enum EMiniCardPanel {
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

export enum ETrackMode {
  DEFAULT = 'DEFAULT',
  FISHING_ACTIVITY = 'FISHING_ACTIVITY',
  MULTI_SPEED = 'MULTI_SPEED'
}

export type TLayerValues = {
  depth: number;
  depthRange: TMinMax;
  opacity: number;
  range: [number, number];
};

/**/
export type TLayerInfo = {
  dateList: TDateListItem[];
  elevationList: number[];
  name:
    | 'PlanktonData'
    | 'PlanktonSmooth'
    | 'SalinityData'
    | 'SalinitySmooth'
    | 'SeaTemperatureData'
    | 'SeaTemperatureSmooth'
    | 'ZooplanktonData'
    | 'ZooplanktonSmooth';
  workspace: 'cns';
};
/**/
export type TDateListItem = {
  dateTime: string; // 2024-07-16T23:56:15Z
  label: string; // Jul 17
};
