import { init } from '@sentry/browser';

import { CHUNK_LOAD_ERROR_FRAGMENTS } from 'other/constants';
import { ENV, VERSION } from 'other/config';
import {
  hasCookieConsent,
  isCordova,
  isLocalHost,
  isProdHost
} from 'other/helpers';
import { initFBP, initGA, initLIT } from 'services/analytics';

// Initializes Sentry error logger
function initSentry(): void {
  init({
    denyUrls: ['https://api4.windy.com', 'https://www.windy.com'],
    dsn: 'https://8d3024788efb46a1b182c2d14720d816@sentry.io/1393586',
    ignoreErrors: [
      ...CHUNK_LOAD_ERROR_FRAGMENTS,
      "Cannot read property '_leaflet_pos' of undefined",
      "Cannot read property 'bindFramebuffer' of null",
      "null is not an object (evaluating 't.addedIndex')",
      'Object captured as promise rejection with keys: message, url',
      '[object XMLHttpRequest]',
      'ResizeObserver loop completed with undelivered notifications',
      'The object can not be found here',
      /failed to load tile/i,
      /out of memory/i,
      /_leaflet_pos/
    ],
    release: `${ENV.APP_NAME}@${VERSION}`
  });
}

// Initializes Tracking
function initTracking(): void {
  initFBP();
  initLIT();
  initSentry();
}

export function startTracking(): void {
  initGA();
  if (isProdHost() && (isCordova() || hasCookieConsent())) {
    initTracking();
  }
}

export function shouldRunTracking(): boolean {
  return !isLocalHost();
}
